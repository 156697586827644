import { render, staticRenderFns } from "./video-show.vue?vue&type=template&id=97fa5560&scoped=true"
import script from "./video-show.vue?vue&type=script&lang=js"
export * from "./video-show.vue?vue&type=script&lang=js"
import style0 from "./video-show.vue?vue&type=style&index=0&id=97fa5560&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97fa5560",
  null
  
)

export default component.exports